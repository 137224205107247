<template>
  <mi-td :title="title" class="configuration-details-item vertical-top" no-hover>
    <div class="configuration-details-item__label text-overline text-family-condensed text-weight-bold">
      {{ label }}
    </div>
    <div class="configuration-details-item__content text-subtitle2 text-weight-regular ellipsis-2-lines">
      <slot></slot>
    </div>
  </mi-td>
</template>

<script>
  export default {
    name: 'ConfigurationDetailsContentItem',
    props: {
      label: {
        type: String,
        required: false,
        default: ''
      },
      title: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-details-item {
    padding-top: .625rem;
    padding-bottom: .625rem;

    &__label {
      line-height: 18px;
      margin-bottom: 2px;
    }

    &__content {
      line-height: 18px;
      white-space: normal;
    }
  }
</style>
