<template>
  <mi-markup-table class="configuration-details-table overflow-hidden full-width" separator="cell" bordered>
    <tbody>
      <mi-tr>
        <!-- Name -->
        <content-item :title="currentConfiguration.name" label="Configuration Name">
          {{ currentConfiguration.name || '-' }}
        </content-item>

        <!-- Project -->
        <content-item :title="currentConfiguration.project" label="Project">
          {{ currentConfiguration.project || '-' }}
        </content-item>
      </mi-tr>

      <mi-tr>
        <!-- C4S/AE NR -->
        <content-item :title="currentConfiguration.c4sCode" label="C4S/AE NR">
          {{ currentConfiguration.c4sCode || '-' }}
        </content-item>

        <!-- Description -->
        <content-item :title="currentConfiguration.description" label="Description">
          {{ currentConfiguration.description || '-' }}
        </content-item>
      </mi-tr>

      <mi-tr>
        <!-- Product Model -->
        <content-item :title="currentConfiguration.productModel?.businessName" label="Product Model">
          {{ currentConfiguration.productModel?.businessName || '-' }}
        </content-item>

        <!-- Planning Period -->
        <content-item
          :title="removePrefixFromPlanningPeriod(currentConfiguration.planningPeriodRange?.from)"
          label="Planning Period"
        >
          {{ removePrefixFromPlanningPeriod(currentConfiguration.planningPeriodRange?.from) || '-' }}
        </content-item>
      </mi-tr>

      <mi-tr>
        <!-- Creator -->
        <content-item :title="currentConfiguration.creator" label="Creator">
          {{ currentConfiguration.creator || '-' }}
        </content-item>
        <!-- Editing Permissions -->
        <content-item :title="editingPermissions" label="Editing permissions">
          {{ editingPermissions }}
        </content-item>
      </mi-tr>
      <mi-tr>
        <!-- Windchill ID -->
        <content-item :title="currentConfiguration?.windChillData" label="Windchill ID">
          {{ currentConfiguration?.windChillData?.variantSpecNumber || '-' }}
        </content-item>
      </mi-tr>
    </tbody>
  </mi-markup-table>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'

  import ContentItem from './ConfigurationDetailsContentItem.vue'

  const { mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationDetailsContent',
    components: { ContentItem },
    data: () => ({ removePrefixFromPlanningPeriod }),
    computed: {
      ...mapState(['currentConfiguration']),
      editingPermissions() {
        return this.currentConfiguration.authorizedUsers?.length > 0 ? 'Selected users' : 'Everyone'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-details-table {
    border-color: $mi-anthracite-200;

    ::v-deep(.q-table) {
      table-layout: fixed;

      .mi-td {
        width: 50%;
        border-color: $mi-anthracite-200;
      }
    }
  }
</style>
