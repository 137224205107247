<template>
  <mi-list>
    <!-- Menu options -->
    <mi-list-item
      v-for="({ title, type, exportFn }, key) in SAVE_MENU_OPTIONS"
      :key="key"
      clickable
      :disable="disableUnauthorizedItem(type)"
      @click="exportFn(type)"
    >
      <mi-list-item-section>
        <mi-list-item-label> {{ title }} </mi-list-item-label>
      </mi-list-item-section>
    </mi-list-item>
  </mi-list>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { PAC_SAVE_TYPES } from '@/constants'

  const { mapGetters } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationHeaderSaveMenu',
    emits: ['open:save-dialog'],

    data() {
      return {
        SAVE_MENU_OPTIONS: {
          [PAC_SAVE_TYPES.CURRENT]: {
            title: 'Save current',
            type: PAC_SAVE_TYPES.CURRENT,
            exportFn: this.emitOpenSaveDialog
          },
          [PAC_SAVE_TYPES.NEW]: {
            title: 'Save as a new configuration',
            type: PAC_SAVE_TYPES.NEW,
            exportFn: this.emitOpenSaveDialog
          }
        }
      }
    },
    computed: {
      ...mapGetters(['isAuthorizedToEditCurrentConfiguration'])
    },
    methods: {
      emitOpenSaveDialog(type) {
        this.$emit('open:save-dialog', type)
      },
      disableUnauthorizedItem(type) {
        return !this.isAuthorizedToEditCurrentConfiguration && type === PAC_SAVE_TYPES.CURRENT
      }
    }
  }
</script>
