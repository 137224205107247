<template>
  <mi-dialog
    :model-value="modelValue"
    :persistent="isConfigurationSaveInProgress || isConfigurationSaveNewInProgress"
    class="configuration-save-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-lg"> {{ getContent('title') }} </h5>

    <div v-if="isNewConfiguration" class="configuration-save-new-dialog__content">
      <edit-form
        :initial-configuration="currentConfiguration"
        :is-save-as-new="true"
        @submit="handleSaveNewBtnClick"
      >
        <template #actions>
          <div class="flex justify-end">
            <!-- Cancel button -->
            <mi-btn v-close-popup flat> Cancel </mi-btn>

            <!-- Submit button -->
            <mi-btn :loading="isConfigurationSaveInProgress" type="submit"> {{ getContent('button') }} </mi-btn>
          </div>
        </template>
      </edit-form>
    </div>

    <div v-else class="configuration-save-current-dialog__content">
      <!-- Subtitle -->
      <p class="q-mb-xl"> The previous configuration will be overwritten by this action. </p>
    </div>

    <template v-if="!isNewConfiguration" #actions>
      <!-- Cancel button -->
      <mi-btn v-close-popup flat> Cancel </mi-btn>

      <!-- Submit button -->
      <mi-btn
        :loading="isConfigurationSaveInProgress"
        @click="handleSaveBtnClick"
      >
        {{ getContent('button') }}
      </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { PAC_SAVED_CONFIG, PAC_SAVED_NEW_CONFIG } from '@/utils/analytics/constants'
  import { PAC_SAVE_TYPES } from '@/constants'
  import EditForm from '@/components/pac/new-configuration-stepper/NewConfigurationStepCreateForm.vue'
  import { createFullConfiguration } from '@/api'

  const { mapActions, mapState } = createNamespacedHelpers('pac')

  const SAVE_CONTENT = {
    [PAC_SAVE_TYPES.CURRENT]: {
      title: 'Save Configuration',
      button: 'Save'
    },
    [PAC_SAVE_TYPES.NEW]: {
      title: 'Save as a new configuration',
      button: 'Save as new'
    }
  }
  export default {
    name: 'ConfigurationSaveDialog',
    components: { EditForm },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      saveType: {
        type: String,
        required: true,
        default: PAC_SAVE_TYPES.NONE
      }
    },
    emits: ['update:model-value', 'saved:newConfiguration'],
    data: () => ({
      isConfigurationSaveInProgress: false
    }),
    computed: {
      ...mapState(['currentConfiguration']),

      isNewConfiguration() {
        return PAC_SAVE_TYPES.NEW === this.saveType
      }
    },
    methods: {
      ...mapActions(['saveConfiguration']),

      getContent(type) {
        return SAVE_CONTENT[this.saveType]?.[type] || ''
      },
      async handleSaveBtnClick() {
        this.isConfigurationSaveInProgress = true

        try {
          await this.saveConfiguration(this.currentConfiguration)

          this.emitUpdateModelValue(false)

          notify({
            title: 'Saved',
            content: `Configuration "${ this.currentConfiguration.name || '' }" was successfully saved`,
            type: 'positive'
          })

          // Analytics
          recordAnalytics(PAC_SAVED_CONFIG)
        }
        finally {
          this.isConfigurationSaveInProgress = false
        }
      },
      async handleSaveNewBtnClick({ configuration = this.currentConfiguration } = {}) {
        let configurationId = ''
        this.isConfigurationSaveInProgress = true

        try {
          ({ id: configurationId } = await createFullConfiguration(configuration))

          this.emitUpdateModelValue(false)

          notify({
            title: 'Saved',
            content: `Configuration "${ configuration.name || '' }" was successfully saved as new`,
            type: 'positive'
          })

          // Analytics
          recordAnalytics(PAC_SAVED_NEW_CONFIG)

          this.currentConfiguration = configuration

          configurationId && this.$emit('saved:newConfiguration', configurationId)
        }
        finally {
          this.isConfigurationSaveInProgress = false
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .configuration-save-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
      min-width: 580px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .configuration-save-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .configuration-save-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
