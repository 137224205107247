<template>
  <div class="configuration-info column no-wrap overflow-hidden-y">
    <!-- Tabs -->
    <mi-tabs v-model="activeInfoTab" switch-indicator>
      <!-- Validation tab -->
      <mi-tab
        :badge="validationBadge"
        :name="INFO_TAB_NAMES.VALIDATION"
        class="configuration-info__tab"
        label="Validation"
      ></mi-tab>

      <!-- Explanation tab -->
      <mi-tab
        :badge="explanationBadge"
        :name="INFO_TAB_NAMES.EXPLANATION"
        class="configuration-info__tab"
        label="Explanation"
      ></mi-tab>

      <!-- Ignore list tab -->
      <mi-tab
        :badge="ignoreList.length || ''"
        :name="INFO_TAB_NAMES.IGNORE_LIST"
        class="configuration-info__tab"
        label="Ignore List"
      ></mi-tab>
    </mi-tabs>

    <!-- Tab panels -->
    <mi-tab-panels v-model="activeInfoTab" keep-alive>
      <!-- Validation results -->
      <mi-tab-panel class="configuration-info__tab-panel" :name="INFO_TAB_NAMES.VALIDATION">
        <validation-results @click:validation-item="item => $emit('click:validation-item', item)"></validation-results>
      </mi-tab-panel>

      <!-- Explanation -->
      <mi-tab-panel class="configuration-info__tab-panel" :name="INFO_TAB_NAMES.EXPLANATION">
        <explanation-list @update:explanation-results-length="updateExplanationBadge"></explanation-list>
      </mi-tab-panel>

      <!-- Ignore list -->
      <mi-tab-panel class="configuration-info__tab-panel" :name="INFO_TAB_NAMES.IGNORE_LIST">
        <ignore-list></ignore-list>
      </mi-tab-panel>
    </mi-tab-panels>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import ValidationResults from './validation/ConfigurationInfoValidation.vue'
  import ExplanationList from './ConfigurationInfoExplanation.vue'
  import IgnoreList from './ConfigurationInfoIgnoreList.vue'

  const { mapState } = createNamespacedHelpers('pac')

  const INFO_TAB_NAMES = Object.freeze({
    VALIDATION: 'validation',
    EXPLANATION: 'explanation',
    IGNORE_LIST: 'ignoreList'
  })

  export default {
    name: 'ConfigurationInfo',
    components: {
      ValidationResults,
      ExplanationList,
      IgnoreList
    },
    emits: ['click:validation-item'],
    data: () => ({
      INFO_TAB_NAMES,
      activeInfoTab: INFO_TAB_NAMES.VALIDATION,
      explanationBadge: ''
    }),
    computed: {
      ...mapState({
        ignoreList: ({ currentConfiguration } = {}) => currentConfiguration.ignoreList || [],
        validationBadge: ({ validation } = {}) => (validation.showBadge ? validation.results.length : '')
      })
    },
    methods: {
      updateExplanationBadge(explanationLength = -1) {
        this.explanationBadge = explanationLength > -1 ? explanationLength : ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-info ::v-deep(.q-panel) {
    overflow: hidden;
  }

  .configuration-info__tab ::v-deep(.q-tab__label) {
    font-family: $mi-typography-font-family-condensed;
    font-size: .875rem;
  }

  .configuration-info__tab.q-tab--active,
  .configuration-info__tab-panel {
    background-color: $mi-silver-50;
  }
</style>
