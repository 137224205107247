<template>
  <mi-form>
    <mi-text-field
      v-model="optionsSearchString"
      bg-color="white"
      placeholder="Search"
      clearable
      hide-bottom-space
      outlined
      @clear="clearOptionsSearchField"
    >
      <template #prepend>
        <mi-btn
          class="search-field-submit-btn"
          icon="search"
          icon-type
          type="submit"
          dense
          fab
          flat
          @click="searchConfigurationOptions(optionsSearchString)"
        ></mi-btn>
      </template>
    </mi-text-field>
  </mi-form>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { base64EncodeUnicode } from '@/utils/base64'

  import { SET_CLEAR_CONFIGURATION_OPTIONS_QUERY } from '@/store/modules/pac/mutationTypes'

  const { mapActions, mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationHeaderSearchField',
    data: () => ({
      optionsSearchString: ''
    }),
    computed: {
      ...mapState(['clearConfigurationOptionsQuery'])
    },
    watch: {
      clearConfigurationOptionsQuery: {
        async handler(newValue) {
          if (newValue) {
            if (this.optionsSearchString.length) {
              await this.clearOptionsSearchField()
            }

            this.SET_CLEAR_CONFIGURATION_OPTIONS_QUERY(false)
          }
        }
      }
    },
    methods: {
      ...mapActions(['getConfigurationOptions']),
      ...mapMutations({ SET_CLEAR_CONFIGURATION_OPTIONS_QUERY }),

      async clearOptionsSearchField() {
        this.optionsSearchString = ''

        await this.getConfigurationOptions()
      },
      searchConfigurationOptions(query = '') {
        const searchQuery = query?.split(';')
          .map(item => base64EncodeUnicode(item.trim()))
          .filter(item => item.length)
          .join(',') || ''
        this.getConfigurationOptions(searchQuery)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-field-submit-btn.mi-btn.q-btn--dense {
    padding: 6px;
    margin-left: -6px;

    ::v-deep(.mi-icon) {
      font-size: 20px;
    }
  }
</style>
