<template>
  <mi-list>
    <!-- Menu options -->
    <mi-list-item
      v-for="({ title, filename, exportFn }, type) in EXPORT_MENU_OPTIONS"
      :key="type"
      clickable
      @click="exportFn({ filename, configurationType: type })"
    >
      <mi-list-item-section>
        <mi-list-item-label> {{ title }} </mi-list-item-label>
      </mi-list-item-section>
    </mi-list-item>
    <mi-list-item
      clickable
      :disable="!isAuthorizedToEditCurrentConfiguration"
      @click="emitOpenWindchillDialog"
    >
      <mi-list-item-section>
        <mi-list-item-label> Windchill configuration </mi-list-item-label>
      </mi-list-item-section>
    </mi-list-item>
  </mi-list>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { exportFile } from 'quasar'

  import formatDate from '@/utils/formatDate'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    exportGreenConfiguration,
    exportYellowConfiguration,
    getGreenConfiguration,
    searchOptions
  } from '@/api'

  import { PAC_GREEN, PAC_YELLOW } from '@/utils/analytics/constants'
  import { convertToBlob } from '@/utils/convertBase64'
  import { PM_TYPE } from '@/constants'

  const { mapState } = createNamespacedHelpers('pac')
  const { mapGetters } = createNamespacedHelpers('pac')

  // Local constants
  const EXPORT_TYPES = Object.freeze({
    YELLOW: 'yellow',
    GREEN: 'green',
    WINDCHILL: 'windchill'
  })

  export default {
    name: 'ConfigurationHeaderExportMenu',
    props: {
      executePollRef: {
        type: Object,
        required: false,
        default: null
      }
    },
    emits: ['update:is-exporting', 'open:windchill-dialog'],

    data() {
      return {
        EXPORT_MENU_OPTIONS: {
          [EXPORT_TYPES.YELLOW]: {
            title: 'Yellow configuration',
            filename: 'Yellow_Configuration.xlsx',
            exportFn: this.exportExcelFile
          },
          [EXPORT_TYPES.GREEN]: {
            title: 'Green configuration',
            filename: 'Green_Configuration.xlsx',
            exportFn: this.exportExcelFile
          }
        }
      }
    },
    computed: {
      ...mapState(['currentConfiguration', 'lockedChoicesIds', 'selectedChoices']),
      ...mapGetters(['isAuthorizedToEditCurrentConfiguration'])
    },
    methods: {
      async exportExcelFile({ filename = '', configurationType = '' } = {}) {
        const request = {
          name: this.currentConfiguration.name,
          project: this.currentConfiguration.project,
          c4sCode: this.currentConfiguration.c4sCode,
          planningPeriodRange: this.currentConfiguration.planningPeriodRange,
          description: this.currentConfiguration.description,
          ignoreList: this.currentConfiguration.ignoreList,
          productModelName: this.currentConfiguration.productModel?.businessName,
          exportDate: formatDate(new Date().toISOString()),
          validationStatus: 'not available'
        }
        let file = null

        this.emitUpdateIsExporting(true)

        try {
          if (configurationType === EXPORT_TYPES.YELLOW) {
            request.selectedElements = await this.getSelectedElementsForYellowConfiguration()
            file = await exportYellowConfiguration(request)
            recordAnalytics(PAC_YELLOW)
          }
          else if (configurationType === EXPORT_TYPES.GREEN) {
            request.selectedChoices = this.currentConfiguration.selectedChoices?.map(
              ({ choice = {} } = {}) => ({ codeId: choice.id, domainId: choice.optionId })
            ) || []
            file = await this.exportGreenConfigurationAsync(request)
            recordAnalytics(PAC_GREEN)
          }

          if (file) exportFile(filename, convertToBlob(file, 'excel'))
        }
        finally {
          this.emitUpdateIsExporting()
        }
      },
      async exportGreenConfigurationAsync(params) {
        const { file } = await this.executePollRef({
          correlationIdEndpoint: {
            getCorrelationId: exportGreenConfiguration,
            params
          },
          timeout: 2000,
          asyncEndpoint: getGreenConfiguration
        })

        return file
      },
      async getSelectedElementsForYellowConfiguration() {
        const selectedElements = []

        const { elements: configurationOptions } = await searchOptions({
          pmIdentifier: this.currentConfiguration.productModel?.encodedBusinessName,
          pmType: PM_TYPE.PRODUCT_MODEL,
          numberOfElements: 0
        })

        for (const option of configurationOptions) {
          const selectedElement = {
            categoryName: option.categoryName,
            optionId: option.id,
            optionId2: option.id2,
            optionName: option.name
          }
          const selectedChoices = this.selectedChoices.get(selectedElement.optionId) || []
          let type = 'MissingOption'

          if (selectedChoices.length) {
            const lockedChoices = this.lockedChoicesIds.get(selectedElement.optionId) || []

            type = selectedChoices.length > 1 ? 'AmbiguousOption' : 'CorrectOption'

            for (const choice of selectedChoices) {
              const locked = lockedChoices.includes(choice.id)

              selectedElements.push({
                ...selectedElement,
                type,
                locked,
                choiceId: choice.id,
                choiceId2: choice.id2,
                choiceName: choice.name
              })
            }
          }
          else {
            selectedElements.push({ ...selectedElement, type })
          }
        }

        return selectedElements
      },
      emitOpenWindchillDialog() {
        this.$emit('open:windchill-dialog')
      },
      emitUpdateIsExporting(value = false) {
        this.$emit('update:is-exporting', value)
      }
    }
  }
</script>
