<template>
  <info-card
    :caption="`${ ignoreList.length } result(s) shown`"
    :items="ignoreList"
    no-data-label="Ignore list is empty"
  >
    <!-- Ignore list item -->
    <template #item="{ item }">
      <mi-list-item
        :key="item.id"
        class="ignore-list-item bg-white"
        dense
      >
        <!-- Content -->
        <mi-list-item-section>
          <mi-list-item-label>
            <span class="vertical-middle"> {{ item.id2 }} </span>
            <!-- Windchill link -->
            <!-- //NOSONAR -->
            <mi-btn
              :href="getWindchillLink(item)"
              class="explanation-item__windchill-link q-ml-xs"
              icon="info-circle-outline"
              icon-type
              icon-size="14px"
              rel="noreferrer"
              target="_blank"
              type="a"
              dense
              fab
              flat
            >
              <mi-tooltip> {{ item.id2 }} </mi-tooltip>
            </mi-btn>
          </mi-list-item-label>
        </mi-list-item-section>

        <mi-list-item-section side>
          <!-- Remove rule from ignore list button -->
          <mi-btn
            class="ignore-list-item__action-btn"
            color="negative"
            icon="minus-circle"
            icon-type
            icon-size="14px"
            dense
            fab
            flat
            @click="REMOVE_RULE_FROM_IGNORE_LIST(item.id)"
          ></mi-btn>
        </mi-list-item-section>
      </mi-list-item>
    </template>
  </info-card>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import { REMOVE_RULE_FROM_IGNORE_LIST } from '@/store/modules/pac/mutationTypes'
  import InfoCard from '@/components/pac/configuration/info/ConfigurationInfoCard.vue'
  import { EXPLANATION_RULE_WINDCHILL_LINK } from '@/constants'
  import { createWindchillLink } from '@/utils/windchill'

  const { mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationInfoIgnoreList',
    components: { InfoCard },
    computed: {
      ...mapState({
        ignoreList: ({ currentConfiguration } = {}) => currentConfiguration.ignoreList || []
      })
    },
    methods: {
      ...mapMutations({ REMOVE_RULE_FROM_IGNORE_LIST }),

      getWindchillLink(item) {
        return createWindchillLink(EXPLANATION_RULE_WINDCHILL_LINK, item.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ignore-list-item__action-btn.mi-btn.q-btn--fab.q-btn--dense {
    padding: .25rem;
    margin-right: -.25rem;
  }

  .explanation-item__windchill-link.mi-btn.q-btn--fab.q-btn--dense {
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: .25rem;

    ::v-deep(.mi-icon.on-left) {
      margin-right: auto;
    }
  }
</style>
