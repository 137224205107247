<template>
  <mi-dialog
    :model-value="modelValue"
    :persistent="isConfigurationDeleteInProgress"
    class="configuration-delete-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-lg"> Delete Configuration? </h5>

    <!-- Subtitle -->
    <p class="q-mb-xl"> Are you sure you want to delete configuration <strong> {{ configuration.name }} </strong>? </p>

    <template #actions>
      <!-- Cancel button -->
      <mi-btn v-close-popup flat> Cancel </mi-btn>

      <!-- Submit button -->
      <mi-btn :loading="isConfigurationDeleteInProgress" @click="triggerConfigurationDelete"> Delete </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import { deleteConfiguration } from '@/api'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { PAC_DELETED_CONFIG } from '@/utils/analytics/constants'

  export default {
    name: 'ConfigurationDeleteDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      configuration: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    emits: ['delete:configuration', 'update:model-value'],
    data: () => ({
      isConfigurationDeleteInProgress: false
    }),
    methods: {
      async triggerConfigurationDelete() {
        this.isConfigurationDeleteInProgress = true

        try {
          await deleteConfiguration(this.configuration.id)

          this.emitUpdateModelValue()
          this.$emit('delete:configuration')

          notify({
            title: 'Deleted',
            content: `Configuration "${ this.configuration.name || '' }" was successfully deleted`,
            type: 'positive'
          })

          // Analytics
          recordAnalytics(PAC_DELETED_CONFIG)
        }
        finally {
          this.isConfigurationDeleteInProgress = false
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .configuration-delete-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
      min-width: 580px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .configuration-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .configuration-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
