<template>
  <mi-card class="configuration-details-card">
    <mi-card-section class="row items-center justify-between q-pb-sm configuration-details-card__header">
      <!-- Title -->
      <h6 class="q-my-none"> Configuration details </h6>

      <div class="configuration-details-card-icons">
        <!-- Edit configuration dialog trigger -->
        <mi-btn
          :disable="!currentConfiguration.id"
          class="configuration-details-card__edit-btn"
          icon="edit"
          icon-type
          icon-size="14px"
          dense
          fab
          flat
          @click="isConfigurationEditDialogShown = true"
        ></mi-btn>

        <mi-btn
          class="configuration-details-card__edit-btn"
          :class="collapsedDetails ? 'collapsed-icon' : ''"
          icon-type
          icon-size="14px"
          dense
          fab
          flat
          @click="collapsedDetails = !collapsedDetails"
        >
          <img src="@/assets/images/arrow_up.svg" alt="Close Configuration Details" />
        </mi-btn>
      </div>
    </mi-card-section>

    <!-- Content -->
    <mi-card-section class="q-pt-sm" :class="collapsedDetails ? 'collapsed' : ''">
      <details-content></details-content>
    </mi-card-section>
  </mi-card>

  <!-- Edit configuration dialog -->
  <edit-dialog v-model="isConfigurationEditDialogShown"></edit-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import EditDialog from '@/components/pac/configuration/dialogs/ConfigurationEditDialog.vue'
  import DetailsContent from './ConfigurationDetailsContent.vue'

  const { mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationDetails',
    components: {
      EditDialog,
      DetailsContent
    },
    data: () => ({
      isConfigurationEditDialogShown: false,
      collapsedDetails: true
    }),
    computed: {
      ...mapState(['currentConfiguration'])
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-details-card {
    background-color: $mi-silver-50;

    &__header {
      margin-bottom: 1%;
    }

    &__edit-btn.mi-btn.q-btn--fab {
      padding: .25rem;
    }
  }

  .collapsed {
    display: none;
  }

  .collapsed-icon {
    transform: rotateZ(180deg);
  }
</style>
