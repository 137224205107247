<template>
  <mi-dialog
    class="pac-edit-dialog"
    :model-value="modelValue"
    persistent
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-lg">Edit Configuration</h5>

    <!-- Edit form -->
    <edit-form
      :initial-configuration="currentConfiguration"
      :is-edit="true"
      :error-message-unauthorized="errorMessageUnauthorized"
      @submit="editConfiguration"
    >
      <template #actions>
        <div class="flex justify-end">
          <!-- Cancel button -->
          <mi-btn v-close-popup class="q-mr-sm" flat> Cancel </mi-btn>

          <!-- Submit button -->
          <mi-btn
            :loading="isConfigurationEditInProgress"
            :disable="!isAuthorizedToEditCurrentConfiguration"
            type="submit"
          >
            Edit
          </mi-btn>
        </div>
      </template>
    </edit-form>
  </mi-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import EditForm from '@/components/pac/new-configuration-stepper/NewConfigurationStepCreateForm.vue'
  import { PAC_EDITED_CONFIG } from '@/utils/analytics/constants'

  const { mapActions, mapState, mapGetters } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationEditDialog',
    components: { EditForm },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      isConfigurationEditInProgress: false,
      errorMessageUnauthorized: ''
    }),
    computed: {
      ...mapState(['currentConfiguration']),
      ...mapGetters(['isAuthorizedToEditCurrentConfiguration'])
    },
    methods: {
      ...mapActions(['saveConfiguration']),

      async editConfiguration({ configuration = {} } = {}) {
        this.isConfigurationEditInProgress = true

        try {
          await this.saveConfiguration(configuration)
          // Analytics
          recordAnalytics(PAC_EDITED_CONFIG)

          this.emitUpdateModelValue()
        }
        catch (error) {
          this.errorMessageUnauthorized = error.response?.data
        }
        finally {
          this.isConfigurationEditInProgress = false
        }
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      }
    }
  }
</script>

<style lang="scss">
  .pac-edit-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
      min-width: 580px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .pac-edit-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .pac-edit-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
