<template>
  <info-card
    :caption="`${ explanation.length } result(s) shown`"
    :items="explanation"
    :loading="isExplanationLoading"
    loading-label="Loading explanation. Please wait ..."
    no-data-label="No explanation found"
  >
    <template #caption>
      <div class="q-mb-md">
        <!-- Caption -->
        <span class="configuration-info-card__caption text-overline text-weight-bold">
          {{ explanation.length }} result(s) shown
        </span>

        <!-- Ignore list items badge -->
        <mi-badge v-show="explanation.length && currentConfiguration.ignoreList?.length" class="q-ml-sm">
          {{ currentConfiguration.ignoreList?.length }} in ignore list
        </mi-badge>
      </div>
    </template>

    <!-- Explanation item -->
    <template #item="{ item }">
      <mi-list-item
        :key="item.id"
        :class="{ 'explanation-item--dimmed': currentConfiguration.ignoreList?.some(rule => rule.id === item.id) }"
        class="explanation-item bg-white"
        dense
      >
        <!-- Content -->
        <mi-list-item-section>
          <mi-list-item-label>
            <span class="vertical-middle"> {{ item.id2 }} </span>

            <!-- Windchill link -->
            <!-- //NOSONAR -->
            <mi-btn
              :href="item.link"
              class="explanation-item__windchill-link q-ml-xs"
              icon="info-circle-outline"
              icon-type
              icon-size="14px"
              rel="noreferrer"
              target="_blank"
              type="a"
              dense
              fab
              flat
            >
              <mi-tooltip> {{ item.title }} </mi-tooltip>
            </mi-btn>
          </mi-list-item-label>
        </mi-list-item-section>

        <mi-list-item-section side>
          <!-- Add rule to ignore list button -->
          <mi-btn
            v-show="!currentConfiguration.ignoreList?.some(rule => rule.id === item.id)"
            class="explanation-item__action-btn"
            icon="plus-circle"
            icon-type
            icon-size="14px"
            dense
            fab
            flat
            @click="ADD_RULE_TO_IGNORE_LIST({ id: item.id, id2: item.id2, link: item.link, title: item.title })"
          ></mi-btn>

          <!-- Remove rule from ignore list button -->
          <mi-btn
            v-show="currentConfiguration.ignoreList?.some(rule => rule.id === item.id)"
            class="explanation-item__action-btn"
            color="negative"
            icon="minus-circle"
            icon-type
            icon-size="14px"
            dense
            fab
            flat
            @click="REMOVE_RULE_FROM_IGNORE_LIST(item.id)"
          ></mi-btn>
        </mi-list-item-section>
      </mi-list-item>
    </template>

    <!-- Validate button -->
    <template #actions>
      <mi-btn
        color="accent"
        @click="getExplanation()"
      >
        Explanation
      </mi-btn>
    </template>
  </info-card>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import {
    requestConfigurationExplanation,
    getConfigurationExplanation
  } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { ADD_RULE_TO_IGNORE_LIST, REMOVE_RULE_FROM_IGNORE_LIST } from '@/store/modules/pac/mutationTypes'
  import { EXPLANATION_RULE_WINDCHILL_LINK } from '@/constants'

  import InfoCard from '@/components/pac/configuration/info/ConfigurationInfoCard.vue'
  import { PAC_EXPLANATION_REQUESTED } from '@/utils/analytics/constants'
  import { poll } from '@/api/polling'
  import notify from '@/utils/notify'
  import { createWindchillLink } from '@/utils/windchill'

  const { mapGetters, mapMutations, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationInfoExplanation',
    components: { InfoCard },
    emits: ['update:explanation-results-length'],
    data: () => ({
      isExplanationLoading: false,
      explanation: [],
      clearPoll: null
    }),
    computed: {
      ...mapGetters(['configurationProductModelId', 'simplifiedSelectedChoices']),
      ...mapState(['currentConfiguration'])
    },
    beforeUnmount() {
      this.clearPoll?.()
    },
    mounted() {
      if (this.currentConfiguration.ignoreList?.length) {
        this.explanation = this.addLinkAndTitleToExplanationItems(this.currentConfiguration.ignoreList)
      }
    },
    methods: {
      ...mapMutations({ ADD_RULE_TO_IGNORE_LIST, REMOVE_RULE_FROM_IGNORE_LIST }),
      async getExplanation() {
        this.isExplanationLoading = true

        this.emitUpdateExplanationResultsLength()
        this.explanation = []

        try {
          const { executePoll, clearPoll } = poll()
          this.clearPoll = clearPoll

          const response = await executePoll({
            correlationIdEndpoint: {
              getCorrelationId: requestConfigurationExplanation,
              params: {
                ...this.currentConfiguration,
                productModel: {
                  ...this.currentConfiguration.productModel,
                  id: this.configurationProductModelId
                },
                selectedChoices: this.simplifiedSelectedChoices
              }
            },
            asyncEndpoint: getConfigurationExplanation
          })

          const explanations = response.explanations || response.explanationResponse.explanations

          this.explanation = this.addLinkAndTitleToExplanationItems(explanations)
          this.emitUpdateExplanationResultsLength(explanations.length)

          // Analytics
          recordAnalytics(PAC_EXPLANATION_REQUESTED)
        }
        catch (e) {
          notify({
            title: `HTTP Error ${ e.status || '' }`,
            content: 'Fetch PAC explanation',
            type: 'negative'
          })
        }
        finally {
          this.isExplanationLoading = false
        }
      },
      addLinkAndTitleToExplanationItems(explanation = []) {
        return Object.freeze(explanation.map(({ id = '', id2 = '' } = {}) => {
          const title = `Open ${ id2 } in Windchill`
          const link = createWindchillLink(EXPLANATION_RULE_WINDCHILL_LINK, id)
          return { id, id2, link, title }
        }))
      },
      emitUpdateExplanationResultsLength(explanationLength = -1) {
        this.$emit('update:explanation-results-length', explanationLength)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .explanation-item--dimmed {
    opacity: .6;
  }

  .explanation-item__windchill-link.mi-btn.q-btn--fab.q-btn--dense {
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: .25rem;

    ::v-deep(.mi-icon.on-left) {
      margin-right: auto;
    }
  }

  .explanation-item__action-btn.mi-btn.q-btn--fab.q-btn--dense {
    padding: .25rem;
    margin-right: -.25rem;
  }
</style>
