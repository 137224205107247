<template>
  <mi-dialog
    :model-value="modelValue"
    class="validation-result-delete-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-lg"> Unselect invalid choice? </h5>

    <!-- Subtitle -->
    <p v-if="!removeAllChoices" class="q-mb-xl">
      Are you sure you want to remove the <b>{{ choice.choiceId2 }}</b> invalid choice?
    </p>
    <p v-else class="q-mb-xl"> Are you sure you want to remove all of the invalid choices? </p>

    <template #actions>
      <!-- Cancel button -->
      <mi-btn v-close-popup flat @click="emitUnselectChoice"> Cancel </mi-btn>

      <!-- Submit button -->
      <mi-btn
        @click="removeAllChoices ? unselectAllInvalidChoices() : unselectInvalidChoice()"
      >
        Unselect
      </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import notify from '@/utils/notify'

  const { mapActions } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationInfoValidationDeleteDialog',
    props: {
      choice: {
        type: Object,
        required: true
      },
      removeAllChoices: {
        type: Boolean,
        required: false,
        default: false
      },
      modelValue: {
        type: Boolean,
        required: true
      }
    },
    emits: ['unselect:choice', 'update:model-value'],
    methods: {
      ...mapActions(
        ['removeSelectedChoiceFromConfiguration', 'removeAllInvalidChoicesFromConfiguration']
      ),

      unselectAllInvalidChoices() {
        this.removeAllInvalidChoicesFromConfiguration()
        this.emitUpdateModelValue()
        notify({
          title: 'Unselected',
          content: 'All Invalid choices were successfully unselected',
          type: 'positive'
        })
      },

      unselectInvalidChoice() {
        const { choiceId2 } = this.choice
        this.removeSelectedChoiceFromConfiguration(this.choice)
        this.emitUpdateModelValue()
        this.emitUnselectChoice()
        notify({
          title: 'Unselected',
          content: `Invalid choice "${ choiceId2 || '' }" was successfully unselected`,
          type: 'positive'
        })
      },
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      },
      emitUnselectChoice() {
        this.$emit('unselect:choice')
      }
    }
  }
</script>

<style lang="scss">
  .validation-result-delete-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
      min-width: 580px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .validation-result-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .validation-result-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
