<template>
  <div class="configuration-info-card column no-wrap full-height">
    <!-- Caption -->
    <slot name="caption">
      <div class="flex justify-between items-center q-mb-sm">
        <div
          v-if="caption"
          class="configuration-info-card__caption text-overline text-weight-bold"
        >
          {{ caption }}
        </div>
        <slot name="removeAllAction"></slot>
      </div>
    </slot>

    <!-- Items -->
    <mi-list
      v-show="items.length"
      class="configuration-info-card__content overflow-auto text-weight-medium q-pr-sm"
      separator
    >
      <template v-for="(item, index) in items">
        <slot :item="item" :index="index" name="item">
          <mi-list-item :key="index">
            <mi-list-item-section>
              <mi-list-item-label> {{ item }} </mi-list-item-label>
            </mi-list-item-section>
          </mi-list-item>
        </slot>
      </template>
    </mi-list>

    <!-- No data label -->
    <slot name="no-data">
      <!-- INITIAL / ERROR STATE -->
      <div v-show="!items.length && initialState" class="flex-center column q-py-sm">
        <mi-icon class="q-mb-sm" name="warning-circle" size="1.5rem"></mi-icon>
        <span class="text-body2 text-weight-bold text-primary text-family-condensed">
          {{ noItemsFoundLabel }}
        </span>
      </div>

      <!-- VALID STATE -->
      <div
        v-show="!items.length && !initialState && isValidValidation"
        data-testid="valid-configuration"
        class="flex-center column q-py-sm"
      >
        <mi-icon class="q-mb-sm" name="check-circle" size="1.5rem" color="green"></mi-icon>
        <span class="text-body2 text-weight-bold text-primary text-family-condensed">
          {{ validDataLabel }}
        </span>
      </div>

      <!-- INVALID STATE -->
      <div
        v-show="errorCode === 'FIXED_ASSUMPTIONS_INVALID' && !initialState && !isValidValidation"
        class="flex-center column q-py-sm"
      >
        <mi-icon class="q-mb-sm" name="lock-circle" size="1.5rem"></mi-icon>
        <span class="text-body2 text-weight-bold text-primary text-family-condensed">
          {{ invalidItemsLabel }}
        </span>
      </div>
    </slot>

    <!-- Inner loading -->
    <slot name="loading">
      <mi-inner-loading class="configuration-info-card__inner-loading" :showing="loading">
        <mi-spinner class="q-mb-sm" size="36px"></mi-spinner>
        <span
          class="text-body2 text-weight-bold text-primary text-family-condensed"
        > {{ loadingLabel }}
        </span>
      </mi-inner-loading>
    </slot>

    <slot></slot>

    <!-- Actions -->
    <div class="flex justify-end q-pt-md" data-cy="configuration-info-card-actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const { mapState } = createNamespacedHelpers('pac')
  export default {
    name: 'ConfigurationInfoCard',
    props: {
      caption: {
        type: String,
        required: false,
        default: ''
      },
      items: {
        type: Array,
        required: false,
        default: () => []
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      loadingLabel: {
        type: String,
        required: false,
        default: 'Loading. Please wait ...'
      },
      noItemsFoundLabel: {
        type: String,
        required: false,
        default: 'No items found'
      },
      validDataLabel: {
        type: String,
        required: false,
        default: 'This configuration is valid'
      },
      invalidItemsLabel: {
        type: String,
        required: false,
        default: 'This combination is invalid. '
          + 'Make sure to unlock unneeded choices so there could be suggestions to be presented'
      },
      initialState: {
        type: Boolean,
        required: false,
        default: true
      },
      isValidValidation: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapState(['errorCode'])
    }
  }
</script>

<style lang="scss">
  .configuration-info-card__caption {
    color: $mi-silver-700;
  }
</style>

<style lang="scss" scoped>
  .configuration-info-card__content {
    margin-right: -.5rem;
  }

  .configuration-info-card__inner-loading {
    background-color: $mi-silver-50;
  }
</style>
